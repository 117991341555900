import { useMemo } from "react";
import { stringToBool } from "utils/bool";

/**
 * A custom React hook that abstracts and simplifies accessing environment variables related to application features.
 * This hook converts specified environment variables from strings to boolean values, providing these configurations
 * in a structured and accessible way for the application.
 *
 * @returns {Object} An object containing:
 *  - `isSpanishEnabled`: {boolean} - True if the Spanish language option is enabled, false otherwise.
 *  - `isPremiumAvailable`: {boolean} - True if premium features are available, false otherwise.
 *
 * @example
 * const Features = () => {
 *   const { isSpanishEnabled, isPremiumAvailable } = useEnvVars();
 *
 *   return (
 *     <div>
 *       {isSpanishEnabled && <p>Spanish is available.</p>}
 *       {isPremiumAvailable && <p>Premium features are unlocked!</p>}
 *     </div>
 *   );
 * };
 */
const useEnvVars = () => {
  // Memoize the environment variables to prevent re-calculations if they haven't changed.
  const envVars = useMemo(
    () => ({
      REACT_APP_SPANISH_ENABLED: process.env.REACT_APP_SPANISH_ENABLED,
      REACT_APP_PREMIUM_AVAILABLE: process.env.REACT_APP_PREMIUM_AVAILABLE,
      REACT_APP_URL: process.env.REACT_APP_URL,
    }),
    []
  );

  // Use another useMemo to parse the environment variables to booleans only when they change.
  // This is useful in development mode where environment variables might change without a page reload.
  return useMemo(
    () => ({
      isSpanishEnabled: stringToBool(envVars.REACT_APP_SPANISH_ENABLED),
      isPremiumAvailable: stringToBool(envVars.REACT_APP_PREMIUM_AVAILABLE),
      baseURL: envVars.REACT_APP_URL,
    }),
    [
      envVars.REACT_APP_SPANISH_ENABLED,
      envVars.REACT_APP_PREMIUM_AVAILABLE,
      envVars.REACT_APP_URL,
    ]
  );
};

export default useEnvVars;
