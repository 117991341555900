import "./index.css";
import "config/i18n";

import { ChakraProvider } from "@chakra-ui/react";
import { AppProvider } from "contexts/AppContext";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { SiteRouter } from "routes/Router";
import theme from "theme/theme";
import ScrollToTop from "utils/ScrollToTop";

import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <Router>
        <ScrollToTop>
          <ChakraProvider theme={theme}>
            <SiteRouter />
          </ChakraProvider>
        </ScrollToTop>
      </Router>
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
