import { FullScreenLoader } from "components/Loading/FullscreenLoader";
import { NotFound404 } from "components/pages/NotFound404";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Layout } from "./Layout";

const Home = lazy(() =>
  import("components/Home").then((module) => ({
    default: module.Home,
  }))
);
const PrivacyPolicy = lazy(() =>
  import("components/pages/PrivacyPolicy").then((module) => ({
    default: module.PrivacyPolicy,
  }))
);
const SupportUs = lazy(() =>
  import("components/pages/SupportUs").then((module) => ({
    default: module.SupportUs,
  }))
);

export const SiteRouter = () => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route element={<Layout />} path="/">
          <Route element={<Home />} index />
          <Route element={<PrivacyPolicy />} path="privacy-policy" />
          <Route element={<SupportUs />} path="support-us" />
          <Route element={<NotFound404 />} path="*" />
        </Route>
      </Routes>
    </Suspense>
  );
};
