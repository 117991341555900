import { node } from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const handleNavigation = () => {
      if (location.hash) {
        const element = document.getElementById(location.hash.slice(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      }
    };
    setTimeout(handleNavigation, 0);
  }, [location.pathname, location.hash]);

  return <>{children}</>;
};

ScrollToTop.propTypes = {
  children: node.isRequired,
};
export default ScrollToTop;
