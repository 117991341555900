import { useEffect, useState } from "react";

/**
 * Custom React hook for managing session storage entries.
 *
 * This hook provides a straightforward API for getting, setting, and persisting session storage values
 * throughout the lifecycle of a session in a React application.
 *
 * @param {string} key - The key under which to store the value in session storage.
 * @param {any} initialValue - The initial value to use if there is no value in session storage yet.
 * @returns {Array} A tuple containing the current session storage value, and a function to update this value.
 *
 * @example
 * // How to use the useSessionStorage hook
 * const [sessionValue, setSessionValue] = useSessionStorage('sessionKey', 'defaultValue');
 *
 * // Updating the session storage value
 * setSessionValue('newValue');
 */

function useSessionStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.log(error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}

export default useSessionStorage;
