import { Box, Button, Text } from "@chakra-ui/react";
import useSessionStorage from "hooks/useSessionStorage";
import { useTranslation } from "react-i18next";

export const CookiesNotification = () => {
  const { t } = useTranslation();
  const [isDismissed, setDismissed] = useSessionStorage(
    "cookiesNoticeDismissed",
    false
  );

  const handleClose = () => {
    setDismissed(true);
  };

  if (isDismissed) {
    return null;
  }

  return (
    <Box
      bg="blue.600"
      bottom="0"
      boxShadow="0px -2px 10px rgba(0, 0, 0, 0.1)"
      color="white"
      left="0"
      p={{ base: "2", md: "4" }}
      position="fixed"
      right="0"
      textAlign="center"
      w="full"
      zIndex="tooltip"
    >
      <Text fontSize={{ base: "sm", md: "md" }} p={2}>
        {t("global.weValueYourPrivacy")}
      </Text>
      <Button colorScheme="blue" mt={2} onClick={handleClose} size="sm">
        {t("global.ok")}
      </Button>
    </Box>
  );
};
