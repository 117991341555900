import { Box, Flex, Link as ChakraLink, Text } from "@chakra-ui/react";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import ScrollToTopButton from "components/ScrollToTopButton";
import useCopyrightDate from "hooks/useCopyrightDate";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { t } = useTranslation();
  const copyrightYear = useCopyrightDate(2024);

  return (
    <Box as="footer" bgColor="gray.800" color="white" mt={10} px={4} py={5}>
      <Box as="hr" borderColor="gray.700" mb={4} />
      <LanguageSwitcher />
      <Flex alignItems="center" justifyContent="center">
        <ChakraLink as={Link} color="teal.200" fontSize="sm" to="/">
          {t("pages.home")}
        </ChakraLink>
        <Text color="white" mx={2}>
          |
        </Text>
        <ChakraLink
          as={Link}
          color="teal.200"
          fontSize="sm"
          to="/privacy-policy"
        >
          {t("pages.privacyPolicy")}
        </ChakraLink>
        <Text color="white" mx={2}>
          |
        </Text>
        <ChakraLink as={Link} color="teal.200" fontSize="sm" to="/support-us">
          {t("pages.supportUs")}
        </ChakraLink>
      </Flex>
      <ScrollToTopButton />
      <Text fontSize="sm" mt={4} textAlign="center">
        © {copyrightYear} {t("fjordlightMedia")} | {t("footer.rightsReserved")}
      </Text>
    </Box>
  );
};
