import { Flex, Spinner, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export const FullScreenLoader = () => {
  return (
    <MotionFlex
      alignItems="center"
      animate={{ opacity: 1 }}
      bg="white"
      bottom="0"
      direction="column"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      justifyContent="center"
      left="0"
      position="fixed"
      right="0"
      role="alert"
      top="0"
    >
      <Spinner
        color="brand.teal"
        emptyColor="gray.200"
        size="xl"
        speed="0.65s"
        thickness="4px"
      />
      <Text aria-live="polite" color="brand.teal" fontSize="lg" mt="4">
        Loading...
      </Text>
    </MotionFlex>
  );
};
