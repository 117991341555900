import { node as nodeProp } from "prop-types";
import { createContext, useContext, useState } from "react";

const defaultState = {
  events: [],
};

export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState(defaultState);

  const resetAppState = () => {
    setAppState(defaultState);
  };

  const handleEventChange = (events = []) => {
    setAppState((prevState) => ({ ...prevState, events }));
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        handleEventChange,
        resetAppState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: nodeProp.isRequired,
};
