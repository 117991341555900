import { IconButton } from "@chakra-ui/react";
import { FiArrowUp } from "react-icons/fi";

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <IconButton
      aria-label="Scroll to top"
      bottom="20px"
      colorScheme="teal"
      icon={<FiArrowUp />}
      isRound
      onClick={scrollToTop}
      position="fixed"
      right="20px"
      size="lg"
      zIndex="tooltip"
    />
  );
};

export default ScrollToTopButton;
