import { Box, Flex, Link as ChakraLink, Text } from "@chakra-ui/react";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <Box bg="brand.teal" color="white" px={{ base: 3, md: 4 }} py={3}>
      <Flex alignItems="center" justifyContent="space-between" wrap="wrap">
        <ChakraLink as={Link} style={{ textDecoration: "none" }} to="/">
          <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold">
            {"ClearCal"}
          </Text>
        </ChakraLink>
        <LanguageSwitcher />
      </Flex>
    </Box>
  );
};
