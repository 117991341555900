import { useMemo } from "react";

/**
 * A custom React hook that generates a copyright date string based on the start year provided.
 * If the current year is the same as the start year, or if no start year is provided,
 * it returns just the current year. Otherwise, it returns a range from the start year to the current year.
 *
 * @param {number} [startYear] - The year when the copyright protection started.
 * @returns {string} The formatted copyright date string. If `startYear` is provided and it's different from the current year,
 *                   it returns a string formatted as "startYear-currentYear". If `startYear` is not provided or equals the current year,
 *                   it returns the string of the current year alone.
 *
 * @example
 * // Using the hook with a start year
 * const copyright = useCopyrightDate(2010);  // Output: "2010-2023" (assuming the current year is 2023)
 *
 * @example
 * // Using the hook without a start year or when start year is the current year
 * const copyright = useCopyrightDate();      // Output: "2023" (assuming the current year)
 * const copyright2023 = useCopyrightDate(2023);  // Output: "2023"
 */
const useCopyrightDate = (startYear) => {
  const currentYear = new Date().getFullYear();

  return useMemo(() => {
    if (!startYear || startYear === currentYear) {
      return `${currentYear}`;
    }

    return `${startYear}-${currentYear}`;
  }, [startYear, currentYear]);
};

export default useCopyrightDate;
