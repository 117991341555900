import "./LanguageSwitcher.css";

import { Button } from "@chakra-ui/react";
import useEnvVars from "hooks/useEnvVars";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const isEnglish = i18n.language === "en";
  const { isSpanishEnabled } = useEnvVars();

  useEffect(() => {
    if (!isSpanishEnabled) {
      i18n.changeLanguage("en");
    }
  }, [i18n, isSpanishEnabled]);

  const toggleLanguage = () => {
    const newLang = isEnglish ? "es" : "en";
    i18n.changeLanguage(newLang);
  };

  if (!isSpanishEnabled) return null;

  return (
    <Button className="language-switcher" onClick={toggleLanguage} size="sm">
      {isEnglish ? t("languages.spanish") : t("languages.english")}
    </Button>
  );
};
