import { Flex, VStack } from "@chakra-ui/react";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { FullScreenLoader } from "components/Loading/FullscreenLoader";
import { CookiesNotification } from "components/NoCookiesNotification";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <Flex direction="column" minHeight="100vh">
      <Header />
      <Suspense fallback={<FullScreenLoader />}>
        <VStack as="main" flex="1">
          <Outlet />
        </VStack>
      </Suspense>
      <Footer />
      <CookiesNotification />
    </Flex>
  );
};
