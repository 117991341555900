/* eslint-disable sonarjs/no-duplicate-string */
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        field: {
          borderWidth: "0.125rem",
          borderColor: "gray.300",
          _hover: {
            borderColor: "gray.400",
          },
          _focus: {
            borderColor: "brand.teal",
            boxShadow: `0 0 0 1px var(--chakra-colors-brand-teal)`,
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        field: {
          borderWidth: "0.125rem",
          borderColor: "gray.300",
          _hover: {
            borderColor: "gray.400",
          },
          _focus: {
            borderColor: "brand.teal",
            boxShadow: `0 0 0 1px var(--chakra-colors-brand-teal)`,
          },
        },
      },
    },
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: `0 0 0 1px var(--chakra-colors-brand-coral)`,
        },
        _hover: {
          bg: "brand.coral",
          color: "brand.offWhite",
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          borderWidth: "0.125rem",
          borderColor: "gray.300",
          _hover: {
            borderColor: "gray.400",
          },
          _focus: {
            borderColor: "brand.teal",
            boxShadow: `0 0 0 1px var(--chakra-colors-brand-teal)`,
          },
        },
      },
    },
    Menu: {
      parts: ["item", "list"],
      baseStyle: {
        item: {
          _focus: {
            bg: "brand.teal",
            color: "brand.offWhite",
          },
          _hover: {
            bg: "brand.teal",
            color: "brand.offWhite",
          },
          _active: {
            bg: "brand.teal",
            color: "brand.offWhite",
          },
        },
        list: {
          borderColor: "brand.teal",
        },
      },
    },
  },
  colors: {
    brand: {
      teal: "#008080",
      sand: "#F4DECB",
      coral: "#FF6B6B",
      offWhite: "#F9F9F9",
      darkSlate: "#2F4F4F",
    },
  },
});

export default theme;
