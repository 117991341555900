import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export const NotFound404 = () => {
  const { t } = useTranslation("notFound404");
  return (
    <div>
      <Helmet>
        <title>{t("metaTitle")}</title>
        <meta content={t("metaDescription")} name="description" />
      </Helmet>

      <VStack align="center" m="auto" maxW="800px" p={5} spacing={4} w="full">
        <Heading as="h1" color="teal.500" fontWeight="bold" size="2xl">
          {t("heading")}
        </Heading>
        <Text color="gray.500" fontSize="lg">
          {t("offTheSchedule")}
        </Text>

        <Text color="gray.600" fontSize="md">
          {t("backOnTrack")}
        </Text>
        <Box borderRadius="lg" boxShadow="md" p={4}>
          <Button as={RouterLink} colorScheme="teal" size="lg" to="/">
            {t("backHome")}
          </Button>
        </Box>
      </VStack>
    </div>
  );
};
